.login-screen{
    background: white;
    margin-top: 75px;
    .content{
        text-align: center;
        h3{
            font-size:2rem;
            font-weight: bold;

        }
        h1{
            font-size:3rem;
            font-weight: bold;
            text-transform: uppercase;
            color: $color_primary;
        }
        img{
            width: 100%;
        }
    }

    .login-card{
        background: #FFFFFF;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
        border-radius: 47px;
        width:450px;
        margin: auto;
        padding:25px 30px;
        .logo{
            width: 70%;
            height: auto;
            margin: auto;
        }
        form{
            margin-top:30px;
            .form-group{
                label{
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                }
                input{
                    &::placeholder{
                        font-weight: 150;
                        font-size: 14px;
                    }
                }
                .help-link{
                    text-align: right;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    margin-top: 15px;
                    cursor: pointer;
                    
                }
            }
        }
    }
}