@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: 'Poppins', sans-serif;
}

.login-screen {
  background: white;
  margin-top: 75px;
}

.login-screen .content {
  text-align: center;
}

.login-screen .content h3 {
  font-size: 2rem;
  font-weight: bold;
}

.login-screen .content h1 {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #C9022A;
}

.login-screen .content img {
  width: 100%;
}

.login-screen .login-card {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 47px;
  width: 450px;
  margin: auto;
  padding: 25px 30px;
}

.login-screen .login-card .logo {
  width: 70%;
  height: auto;
  margin: auto;
}

.login-screen .login-card form {
  margin-top: 30px;
}

.login-screen .login-card form .form-group label {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
}

.login-screen .login-card form .form-group input::-webkit-input-placeholder {
  font-weight: 150;
  font-size: 14px;
}

.login-screen .login-card form .form-group input:-ms-input-placeholder {
  font-weight: 150;
  font-size: 14px;
}

.login-screen .login-card form .form-group input::-ms-input-placeholder {
  font-weight: 150;
  font-size: 14px;
}

.login-screen .login-card form .form-group input::placeholder {
  font-weight: 150;
  font-size: 14px;
}

.login-screen .login-card form .form-group .help-link {
  text-align: right;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
}

.dashboard_page_container {
  background: whitesmoke;
  min-height: 840px;
}

.dashboard_page_container .dashboard_page .logout-button-holder {
  margin-top: 50px;
  text-align: right;
}

.dashboard_page_container .dashboard_page .logout-button-holder .mr-5 {
  margin-right: 15px;
}

.dashboard_page_container .dashboard_page .welcome {
  margin-bottom: 75px;
  font-weight: bold;
  font-size: 2rem;
}

.dashboard_page_container .dashboard_page .welcome span {
  color: #C9022A;
  text-transform: capitalize;
}

.dashboard_page_container .dashboard_page .main-content .card {
  background: white;
  cursor: pointer;
}

.dashboard_page_container .dashboard_page .main-content .card .logo-div {
  height: 100px;
  background-size: contain;
  width: 80%;
  margin: auto;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}

.dashboard_page_container .dashboard_page .main-content .card p {
  margin-bottom: 0;
}

.dashboard_page_container .dashboard_page .go-app {
  margin-top: 75px;
}

.app_page {
  position: relative;
}

.app_page .fab {
  position: fixed;
  background: #C9022A;
  bottom: 50px;
  left: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.app_page .fab svg {
  font-size: 1.5rem;
  color: white;
}

.app_page .fab:hover {
  background: black;
}

.admin_dashboard .logout-button-holder {
  margin-top: 50px;
  text-align: right;
  margin-bottom: 50px;
}

.admin_dashboard .logo {
  height: 50px;
  margin: auto;
  margin-bottom: 50px;
}

.card-header-admin {
  padding-bottom: 15px;
  border-bottom: 1px solid #C9022A;
}

.card-header-admin .title h4 {
  margin-bottom: 0;
}

.card-header-admin .button {
  text-align: right;
}

.image-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #D9D9D9;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.dashboard-card {
  border: none;
}

.dashboard-card svg {
  color: white;
  font-size: 2.5rem;
}

.gradient-1 {
  background: #59bcff;
  background: linear-gradient(45deg, #59bcff 21%, #59d7ff);
}

.gradient-2 {
  background: #4ad994;
  background: linear-gradient(45deg, #4ad994, #57f998);
}

.gradient-3 {
  background: #fdb765;
  background: linear-gradient(45deg, #fdb765, #ffe485);
}

.gradient-4 {
  background: #ff6d59;
  background: linear-gradient(45deg, #ff6d59, #ffd584);
}

.admin-menu-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.admin-menu-item svg {
  margin-right: 10px;
}

.list-group-flush > .list-group-item {
  border-radius: 36px;
  border-style: none;
}

.main-card {
  max-height: 500px;
  overflow: auto;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-custom {
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 700;
  font-size: 18px;
}

.vh-100 {
  overflow: hidden;
}

.animation {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* Position the close button (top right corner) */
}

.menu .buttons-controller {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 40px;
  cursor: pointer;
}

.menu .buttons-controller button {
  color: white;
  border-color: white;
  margin-right: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.menu .buttons-controller svg {
  color: white;
}

.menu-container {
  margin-top: 100px;
}

.menu-container.home {
  margin-top: 0;
}

.menu-container.roleaccess {
  margin-top: 0;
}

.menu-container .category .category-title {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 25px;
}

.menu-container .category .category-title.white {
  color: white;
}

.menu-container .category .category-title.black {
  color: black;
}

.menu-container .category .url-card {
  margin-top: 10px;
  margin-bottom: 15px;
}

.menu-container .category .url-card.active .card {
  background: lightgreen;
}

.menu-container .category .url-card .card {
  border-radius: 18px;
  cursor: pointer;
}

.menu-container .category .url-card .card:hover {
  background: whitesmoke;
}

.menu-container .category .url-card .image-container {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu-container .category .url-card .image-container .image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #D9D9D9;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px solid #D9D9D9;
}

.menu-container .category .url-card .content-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
}

.menu-container .category .url-card .content-container svg {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 1rem;
}

.menu-container .category .url-card .content-container p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: normal;
}

.menu-container .category .url-card .content-container h3 {
  font-size: .9rem;
  font-weight: bold;
  text-transform: uppercase;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
