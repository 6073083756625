.admin_dashboard{
    .logout-button-holder{
        margin-top: 50px;
        text-align: right;
        margin-bottom: 50px;
    }
    .logo{
        height: 50px;
        margin: auto;
        margin-bottom: 50px;
    }
   
   
}
.card-header-admin{
    padding-bottom: 15px;
    border-bottom: 1px solid $color_primary;
    .title{
        h4{
            margin-bottom: 0;
        }
    }
    .button{
        text-align: right;
    }
}

.image-icon{
    height:30px;
    width:30px;
    border-radius: 50%;
    background-color: #D9D9D9   ;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.dashboard-card{
    border:none;
    svg{
        color:white;
        font-size:2.5rem;
    }
}
.gradient-1{
    background: #59bcff;
    background: linear-gradient(45deg,#59bcff 21%,#59d7ff);
}

.gradient-2 {
    background: #4ad994;
    background: linear-gradient(45deg,#4ad994,#57f998);
}
.gradient-3 {
    background: #fdb765;
    background: linear-gradient(45deg,#fdb765,#ffe485);
}
.gradient-4 {
    background: #ff6d59;
    background: linear-gradient(45deg,#ff6d59,#ffd584);
}

.admin-menu-item{
    display: flex;
    align-items: center;
    svg{
        margin-right: 10px;
    }
}

.list-group-flush>.list-group-item {
    border-radius: 36px;
    border-style: none;
}

.main-card{
    max-height:500px;
    overflow: auto;
}