.btn-rounded{
    border-radius: 50px;

}

.btn-custom{
    padding-left: 25px;
    padding-right: 25px;
font-weight: 700;
font-size: 18px;
}

.vh-100{
    overflow: hidden;
}

.animation{
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}