.app_page{
    position: relative;
    .fab{
        position: fixed;
      
        background:$color_primary;
        bottom: 50px;
        left:25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:15px;
        border-radius: 50%;
        cursor: pointer;
        svg{
            font-size:1.5rem;
            color: white;
        }
        &:hover{
            background: black;
        }
    }
}