.menu {
    height: 100%;
    width: 0;
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0, 0.9); 
    overflow-x: hidden; 
    transition: 0.5s; 

    /* Position the close button (top right corner) */
 .buttons-controller {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 40px;
    cursor: pointer;
    button{
        color: white;
        border-color: white;
        margin-right: 25px;
        padding-left: 25px;
        padding-right: 25px;
    }
    svg{
        color: white;
    }
  }
 

}

 .menu-container{
    margin-top: 100px;
    &.home{
        margin-top: 0;
    }
    &.roleaccess{
        margin-top: 0;
    }
    .category{
        .category-title{
            font-size: 20px;
            text-transform: uppercase;
            margin-top: 25px;
            &.white{
                color: white;
            }
            &.black{
                color: black;
            }
        }
        .url-card{
            margin-top: 10px;
            margin-bottom: 15px;
            &.active{
                .card{
                    background:lightgreen;
                    
                }
            }
            .card{
                border-radius: 18px;
                cursor: pointer;
                &:hover{
                    background:whitesmoke;
                }
            }

            .image-container{
                text-align: center;
                display: flex;
                .image{
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    background-color: #D9D9D9;
                    margin: auto;
                    background-repeat: no-repeat;
                    background-size:contain;
                    background-position: center;
                    border:1px solid #D9D9D9;
                }
            }
            .content-container{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding:0;
                svg{
                    position: absolute;
                    top: 0;
                    right: 10px;
                    font-size:1rem;
                }
                p{
                    margin-bottom: 0;
                    font-size:12px;
                    font-weight: normal;
                }
                h3{
                    font-size:.9rem;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
    }

  }
  
  
  /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }