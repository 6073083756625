.dashboard_page_container{
    background: whitesmoke;
    min-height: 840px;
.dashboard_page{
    .logout-button-holder{
        margin-top: 50px;
        text-align: right;
        // margin-bottom: 50px;
        .mr-5{
            margin-right:15px;
        }
    }
    .welcome{
        margin-bottom: 75px;
        font-weight: bold;
        font-size: 2rem;
        span{
            color: $color_primary;
            text-transform: capitalize;
        }
    }
    .main-content{
        .card{
            background:white;
            cursor: pointer;
            .logo-div{
                height:100px;
                background-size:contain;
                width: 80%;
                margin:auto;
                background-repeat: no-repeat;
                margin-bottom: 30px;
            }
            p{
                margin-bottom: 0;

            }
        }
    }

    .go-app{
        margin-top: 75px;
    }
}
}
